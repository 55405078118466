.Footer {
    padding-top: 42px;
    padding-left: 168px;
    padding-right: 168px;
    padding-bottom: 80px;
    background-color: #EDEDED;
    background-image: url('../../public/assets/images/footer-stars.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
}

.footer-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 41px;
    color: #222;
}

.preparing-to_work,
.how_work {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;

}

.preparing-title {
    cursor: pointer;
}

.footer-list p {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 0;
    margin-top: 10px;
}

.footer-list span {
    font-weight: 700;
    color: #222;
}

.warning {
    margin-top: 58px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #767676;
}

@media screen and (max-width: 768px) {
    .Footer {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 20px;
    }

    .footer-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 34px;
    }

    .how_work {
        border-top: none;
        padding-top: 0 !important;
    }

    .warning {
        margin-top: 20px;
    }

}


@media screen and (max-width: 1050px) {
    .Footer {
        padding-left: 50px;
        padding-right: 50px;
    }

}