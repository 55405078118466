.number-card {
    position: absolute;
    right: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    color: #000000;
    background: rgba(255,255,255,0.4);
    padding: 0 8px;
    border-radius: 20px;
}

.name-card {
    position: absolute;
    bottom: 0;
    left: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    color: #000000;
    background: rgba(255,255,255,0.4);
    padding: 0 8px;
    border-radius: 20px;    
}

.question-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 41px;
    color: #8DB17C;
    margin-top: 20px;
    margin-bottom: 20px;
}

.questions p {
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 0;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
}

.btn-enlarge_card {
    cursor: pointer;
    height: 60px;
    border-radius: 30px;
    border: 1px solid #000000;
    color: #000000;
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    margin-right: 20px;
}

.img-block {
    margin-left: 150px;
    margin-top: 50px;
}

.btn-next_card {
    cursor: pointer;
    height: 60px;
    border-radius: 30px;
    border: none;
    color: #FFFFFF;
    background-color: #8DB17C;
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
}

.questions-block {
    background-color: #EDEDED;
    padding-left: 24px;
    padding-right: 24px;
}
@media screen and (max-width: 768px){
    .questions-block {
        padding-top: 32px
    }
    }
   

.questions-block .questions p {
    color: #000000;
}

@media screen and (max-width: 768px) {
    .btns-block {
        position: absolute;
        bottom: -25px;
        width: 100%;
        text-align: center;
        left: 0;
    }

    .img-block {
        margin-left: 0;
        margin-top: 0;
    }

}

.rotate{
    transform: rotateY(180deg);
    opacity: 0;
}

.card-rotate{
    transition: all .5s;
}