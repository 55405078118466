.main-block {
    background-color: #232323;
    background-image: url(../../public/assets/images/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    color: #FFFFFF;
    padding-left: 168px;
    padding-right: 168px;
    position: relative;
    padding-bottom: 32px;
}



.main_title {
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
}

.main-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}

.btn-get_card {
    cursor: pointer;
    width: 340px;
    height: 60px;
    background-color: #8DB17C;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    border: none;
    border-radius: 30px
}

.right-card {
    margin-left: 204px;
    margin-top: 45px;
}

.center-card {
    left: 155px;
    top: 23px;
}

.center-card img {
    height: 470px;
    border-radius: 20px;
    width: 298px;
}

.btn-get_card.mobile {
    position: absolute;
    bottom: -25px;
}

@media screen and (max-width: 768px) {
    .main-block {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 20px;
        text-align: center;
    }

    .main_title {
        font-size: 24px;
        font-weight: 600;
        line-height: 41px;
    }
}

@media screen and (max-width: 1050px) {
    .main-block {
        padding-left: 50px;
        padding-right: 50px;
    }

    .center-card img {
        height: 470px;
        border-radius: 20px;
        width: 298px;
    }
}