@font-face {
  font-family: 'Gilroy';
  src: url('../public/assets/fonts/Gilroy/gilroy-regular.ttf');
}

body, * {
  font-family: 'Gilroy', sans-serif;
}

body{
  background-color: #EDEDED
}

.App {
  overflow: hidden;
  width: 100%
}

.p-dialog{
  max-height: 100%!important;
}